import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Typography, Button, Link } from '@mui/material';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import img1 from '../../asstes/images/gallery/img1.png'
import img2 from '../../asstes/images/gallery/img2.png'
import img3 from '../../asstes/images/gallery/img3.png'
import img4 from '../../asstes/images/gallery/img4.png'
import { Context as GalleryContext } from "../../context/GalleryContext";
import Colors from '../../asstes/color';
import { useNavigate } from 'react-router-dom';

const GalleryScreen = () => {
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const sections = [
        { title: "Dog Runs", img: img1 },
        { title: "Doggy Paddles", img: img2 },
        { title: "Hikes", img: img3 },
        { title: "Dog Cafes", img: img4 },
    ];

    const { state: { GalleryList }, getAllGallery, clearGetAllGalleryStatus, } = useContext(GalleryContext);

    useEffect(() => {
        getAllGallery()
    }, []);

    const handleImageClick = (section) => {
        navigate('/gallery/details', { state: { data: section } });
    };

    return (
        <Box>
            <NavBar screen={"event"} />

            {/* Gallery Section */}
            <Box textAlign="center" sx={{ py: 0 }}>
                <Typography variant="h3" sx={{
                    fontFamily: 'MyCustomFont',
                    // left: '50%',
                    fontWeight: 700,
                    color: Colors.textSecondary,
                    fontSize: { xs: '1.8rem', md: '3rem' },
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    // zIndex: 1,
                }}>
                    Gallery
                </Typography>
                {GalleryList?.data?.map((section, index) => (
                    <Box key={index} sx={{ my: 4 }}>
                        <Typography variant="h5" sx={{
                            fontFamily: 'MyCustomFont',
                            // left: '50%',
                            fontWeight: 700,
                            color: Colors.textSecondary,
                            fontSize: { xs: '1.3rem', md: '2rem' },
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            // zIndex: 1,
                        }}>
                            {section.name}
                        </Typography>
                        <Box
                            component="img"
                            src={section.mainImage}
                            alt={section.title}
                            onClick={() => handleImageClick(section)}
                            sx={{
                                width: '100%',
                                // width: screenWidth,
                                //maxWidth: '800px',
                                cursor: 'pointer',
                                height:'auto',
                                // height: {xs:'100px',md:'180px'},
                                // objectFit: 'cover',
                                //borderRadius: 1,
                                my: 2,
                                // transition: 'transform 0.3s ease',
                                // '&:hover': { transform: 'scale(1.05)' },
                            }}
                        />
                    </Box>
                ))}
            </Box>

            <Footer />
        </Box>
    );
};

export default GalleryScreen;
