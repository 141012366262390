// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
// import Colors from '../asstes/color'
// import logo from "../asstes/images/logo.png";


// const pages = ['Events', 'FAQs', 'Blog', 'About Us'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// function NavBar() {
//     const [anchorElNav, setAnchorElNav] = React.useState(null);
//     const [anchorElUser, setAnchorElUser] = React.useState(null);

//     const handleOpenNavMenu = (event) => {
//         setAnchorElNav(event.currentTarget);
//     };
//     const handleOpenUserMenu = (event) => {
//         setAnchorElUser(event.currentTarget);
//     };

//     const handleCloseNavMenu = () => {
//         setAnchorElNav(null);
//     };

//     const handleCloseUserMenu = () => {
//         setAnchorElUser(null);
//     };

//     return (
//         <AppBar position="static" style={{ backgroundColor: Colors.background }}>
//             <Container maxWidth="xl">
//                 <Toolbar disableGutters>
//                     <img style={{ height: '52px', width: '52px', top: '36px', left: '46px' }} src={logo} alt="logo" />
//                     <Typography
//                         // variant="h6"
//                         noWrap
//                         component="a"
//                         // href="#app-bar-with-responsive-menu"
//                         sx={{
//                             mr: 2, ml: 2,
//                             display: { xs: 'none', md: 'flex' },
//                             fontFamily: 'MyCustomFont',
//                             fontWeight: 700,
//                             fontSize: 32,
//                             // letterSpacing: '.3rem',
//                             color: Colors.textPrimary,
//                             textDecoration: 'none',
//                         }}
//                     >
//                         Social Paws
//                     </Typography>

//                     {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
//                         <IconButton
//                             size="large"
//                             aria-label="account of current user"
//                             aria-controls="menu-appbar"
//                             aria-haspopup="true"
//                             onClick={handleOpenNavMenu}
//                             color="inherit"
//                         >
//                             <MenuIcon />
//                         </IconButton>
//                         <Menu
//                             id="menu-appbar"
//                             anchorEl={anchorElNav}
//                             anchorOrigin={{
//                                 vertical: 'bottom',
//                                 horizontal: 'left',
//                             }}
//                             keepMounted
//                             transformOrigin={{
//                                 vertical: 'top',
//                                 horizontal: 'left',
//                             }}
//                             open={Boolean(anchorElNav)}
//                             onClose={handleCloseNavMenu}
//                             sx={{ display: { xs: 'block', md: 'none' } }}
//                         >
//                             {pages.map((page) => (
//                                 <MenuItem key={page} onClick={handleCloseNavMenu}>
//                                     <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
//                                 </MenuItem>
//                             ))}
//                         </Menu>
//                     </Box>
//                     <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
//                     <Typography
//                         variant="h5"
//                         noWrap
//                         component="a"
//                         href="#app-bar-with-responsive-menu"
//                         sx={{
//                             mr: 2,
//                             display: { xs: 'flex', md: 'none' },
//                             flexGrow: 1,
//                             fontFamily: 'monospace',
//                             fontWeight: 700,
//                             letterSpacing: '.3rem',
//                             color: Colors.textPrimary,
//                             textDecoration: 'none',
//                         }}
//                     >
//                         LOGO
//                     </Typography> */}

//                     <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: '726px' }}>
//                         {pages.map((page, index) => (
//                             <Button
//                                 key={page}
//                                 onClick={handleCloseNavMenu}
//                                 sx={{
//                                     my: 2, color: Colors.textPrimary, display: 'block', borderRadius: '22px', border: `1.5px solid ${Colors.textPrimary}`, ml: index === 0 ? -2 : 0,
//                                     mr: 2, fontFamily: 'MyCustomFont', fontSize: '14px', fontWeight: 700, textTransform: 'none', py: 0.3,
//                                 }}
//                             >
//                                 {page}
//                             </Button>
//                         ))}
//                     </Box>
//                     {/* </Box> */}
//                 </Toolbar>
//             </Container>
//         </AppBar>
//     );
// }
// export default NavBar;


import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Colors from '../asstes/color';
import logo from "../asstes/images/logo.png";
import { Link } from 'react-router-dom';

const drawerWidth = 200;
const navItems = [
    { label: 'About Us', path: '/team' },
    { label: 'Events', path: '/events' },
    { label: 'Gallery', path: '/gallery' },
    { label: 'FAQs', path: '/faqs' },
    // { label: 'Blog', path: '/blog' },
];
function NavBar(props) {
    // const { window, screen } = props;
    const { screen } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" component={Link}
                to="/" sx={{
                    my: 2, fontFamily: 'MyCustomFont1', textDecoration: 'none', mt: 4, color: Colors.textPrimary, fontWeight: 700,
                    fontSize: 32,
                }}>
                Social Paws
            </Typography>
            {/* <Divider /> */}
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Link}
                            to={item.path} >
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    // const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'block', width: '100%' }}>
            <Box sx={{ display: 'block', height: '104px', padding: { xs: '10px', md: screenWidth < 1512 ? '20px' : '30px' } }}>
                {/* <CssBaseline /> */}
                <AppBar component="nav" position="static" style={{ backgroundColor: screen === "event" ? Colors.white : Colors.background, boxShadow: 'none', width: '100%' }}>
                    {/* position="static" */}
                    <Toolbar>
                        {/* <Box sx={{width:'46px'}}/> top: '36px', left: '46px'*/}
                        <Link to="/">
                            <img style={{ height: screenWidth < 1512 ? '62px' : '104px', width: screenWidth < 1512 ? '62px' : '104px', }} src={logo} alt="logo" />
                        </Link>
                        <IconButton
                            color={Colors.textPrimary}
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{
                                ml: 'auto',
                                display: { sm: 'none' }
                            }}
                        >
                            <MenuIcon sx={{ fontSize: 56 }} />
                        </IconButton>

                        <Typography
                            noWrap
                            component={Link}
                            to="/"
                            sx={{
                                flexGrow: 1,
                                mr: 2,
                                ml: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'MyCustomFont',
                                fontWeight: 700,
                                // fontSize: 32,
                                fontSize: screenWidth < 1512 ? '48px' : '65px',
                                // letterSpacing: '.3rem',
                                color: Colors.textPrimary,
                                textDecoration: 'none',
                            }}
                        >
                            Social Paws
                        </Typography>
                        {/* <Box sx={{ width: '249px', background: 'red' }} /> */}
                        <Box sx={{
                            display: { xs: 'none', sm: 'flex' }, width: '683px', gap: '24px', justifyContent: "flex-end", // Left align buttons
                            // alignItems: "center",
                        }}>
                            {navItems.map((item) => (
                                <Button key={item.label} component={Link}
                                    to={item.path} sx={{
                                        color: Colors.textSecondary, borderRadius: screenWidth < 1512 ? '25px' : '35px', border: `2px solid ${Colors.textSecondary}`,
                                        // mr: 2, 
                                        fontFamily: 'MyCustomFont',
                                        fontSize: screenWidth < 1512 ? '22px' : '30px',
                                        fontWeight: 700, textTransform: 'none',
                                        // py: 0.3,
                                        px: screenWidth < 1512 ? 0.7 : 1.5,
                                        // width:'188px',
                                        height:'41px'
                                        // padding:'5px'
                                    }}>
                                    {item.label}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        anchor="right"
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: Colors.background },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box>
        </Box>
    );
}

NavBar.propTypes = {
    window: PropTypes.func,
};

export default NavBar;