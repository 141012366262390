import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Fab, Stack, Typography, TextField, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
// import bg from '../../asstes/images/bg.png'
import bg from '../../asstes/images/backg.png'
import Colors from '../../asstes/color';
import apple from '../../asstes/images/apple.png'
import google from '../../asstes/images/google.png'
import MessageIcon from '../../asstes/images/msg.png'
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from "@mui/icons-material/Close";
import { Context as ContactContext } from "../../context/ContactContext";
import NavBar from '../../components/NavBar';

const LoveOfDogs = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        subject: false,
        message: false,
    });

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { state: { CreateContactStatus }, createMessage, clearCreateContactStatus, } = useContext(ContactContext);


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (CreateContactStatus === 201) {
            console.log("success")
            // setOpen2(true)
            // setFormValues({
            //     Name: '',
            //     Phone: '',
            //     Email: '',
            // })
            // setSelectedEvent([])
            clearCreateContactStatus();
        } else if (CreateContactStatus === 400 || CreateContactStatus === 404) {
            clearCreateContactStatus();
        }
    }, [CreateContactStatus]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        // Remove error state when user starts typing
        setErrors({ ...errors, [e.target.name]: false });
    };

    const handleSubmit = () => {
        let newErrors = {
            name: formData.name.trim() === "",
            email: !emailRegex.test(formData.email),
            subject: formData.subject.trim() === "",
            message: formData.message.trim() === "",
        };

        setErrors(newErrors);

        if (!Object.values(newErrors).includes(true)) {
            console.log("Form submitted successfully:", formData);
            createMessage({
                name: formData.name,
                email: formData.email,
                subject: formData.subject,
                message: formData.message,
            });
            setOpen(false); // Close form on success
        }
    };


    const handleSubmit1 = () => {
        try {
            // if (selectedEvent.length === 0) {
            //     setOpen1(true);
            // } else if (formValues.Name === '') {
            //     setOpen(true);
            // } else if (formValues.Email === '') {
            //     setOpen(true);
            // } else if (formValues.Phone === '') {
            //     setOpen(true);
            // } else {
            createMessage({
                name: name,
                email: email,
                subject: subject,
                message: message,
            });
            // }
        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };



    return (
        // <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ bgcolor: Colors.background, zIndex: 2, position: 'relative', p: { xs: 2, sm: 4, md: 6 } }} overflow="hidden">
        //     <Grid
        //         container
        //         spacing={1}
        //         alignItems="center"
        //         justifyContent="center"
        //     >

        //         <Grid item xs={12} md={6}>
        //             <Typography
        //                 variant="h4"
        //                 sx={{
        //                     fontWeight: 700,
        //                     fontFamily: 'MyCustomFont',
        //                     fontSize: { xs: '24px', md: '48px' },
        //                     textAlign: { xs: 'left', md: 'left' },
        //                     marginBottom: '16px'
        //                 }}
        //             >
        //                 Share the Love of Dogs!
        //             </Typography>
        //             <Typography
        //                 variant="body1"
        //                 sx={{
        //                     fontFamily: 'MyCustomFont1',
        //                     fontWeight: 600,
        //                     color: Colors.black,
        //                     fontSize: { xs: '16px', md: '20px' },
        //                     textAlign: { xs: 'left', md: 'left' },
        //                     marginBottom: '24px'
        //                 }}
        //             >
        //                 Connecting dog-owners<br /> and non-owners to share<br /> dog care and dog therapy
        //             </Typography>

        //             <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row', md: 'column', }}
        //                 sx={{
        //                     zIndex: 10,
        //                     position: 'relative'
        //                 }}
        //                 width={{ xs: '50%', md: '35%' }}
        //             >
        //                 <a href="https://apps.apple.com/us/app/social-paws/id6443444135" target="_blank" rel="noopener noreferrer" >
        //                     <img style={{ width: '100%', height: '10%', marginLeft: 0 }} src={apple} alt="App Store" />
        //                 </a>
        //                 <a href="https://play.google.com/store/apps/details?id=com.bytes.socialpaws&pli=1" target="_blank" rel="noopener noreferrer">
        //                     <img style={{ width: '100%', height: '10%', marginLeft: 0 }} src={google} alt="Google Play" />
        //                 </a>
        //             </Box>


        //             <Box
        //                 width={{ xs: '100%', md: '100%' }}
        //                 sx={{
        //                     // position: 'relative',
        //                     // overflow: 'hidden',
        //                     // display: 'flex',
        //                     display: { xs: 'none', md: 'flex' },
        //                     height: { xs: '60vh', md: '86%' },
        //                     maxHeight: '90vh',
        //                 }}
        //             >
        //                 <Box
        //                     sx={{
        //                         position: 'absolute',
        //                         top: 25,
        //                         left: 140,
        //                         width: '100%',
        //                         height: '100%',
        //                         backgroundImage: `url(${bg})`,
        //                         backgroundSize: { xs: 'contain', md: 'contain' },
        //                         backgroundPosition: 'center',
        //                         backgroundRepeat: 'no-repeat',
        //                         // opacity: 0.3,                  
        //                         // zIndex: 3,
        //                     }}
        //                 />
        //             </Box>
        //         </Grid>


        //         <Box sx={{ position: 'relative', overflow: 'visible' }}>
        //             {/* Contact Us Form */}
        //             {open && (
        //                 <Box
        //                     sx={{
        //                         position: 'fixed',
        //                         bottom: 80, // Adjust so it's above the button
        //                         right: 20,
        //                         width: { xs: '200px', md: '300px' },
        //                         background: 'white',
        //                         zIndex: 9999,
        //                         borderRadius: '28px',
        //                         p: 2,
        //                         boxShadow: 3,
        //                         alignItems: 'center', justifyContent: 'center'
        //                     }}
        //                 >
        //                     <Box display="flex" justifyContent="space-between" alignItems="center">
        //                         <Typography sx={{
        //                             fontWeight: 600,
        //                             fontFamily: 'MyCustomFont1',
        //                             fontSize: { xs: '20px', md: '24px' },
        //                             textAlign: { xs: 'center', md: 'center' },
        //                             color: Colors.textSecondary,
        //                             // marginBottom: '16px'
        //                         }}>Contact Us!</Typography>
        //                         <IconButton sx={{
        //                             color: Colors.textSecondary,
        //                             // border: '2px solid #2BB1E6', 
        //                             // borderRadius: '50px', 
        //                             // padding: '4px' 
        //                         }} onClick={() => setOpen(false)}>
        //                             <CloseIcon sx={{ fontSize: 30 }} />
        //                         </IconButton>
        //                     </Box>

        //                     <Box sx={{ width: '90%', alignItems: 'center', justifyContent: 'center' }}>
        //                         <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
        //                             Name
        //                         </Typography>
        //                         <TextField
        //                             fullWidth
        //                             name="name"
        //                             // label="Name"
        //                             margin="dense"
        //                             variant="outlined"
        //                             value={formData.name}
        //                             onChange={handleChange}
        //                             error={errors.name}
        //                             helperText={errors.name
        //                                 // ? "Name is required" : ""
        //                             }
        //                             InputProps={{
        //                                 style: {
        //                                     borderRadius: '30px',
        //                                     backgroundColor: Colors.white,
        //                                 }
        //                             }}
        //                             sx={{
        //                                 "& .MuiOutlinedInput-root": {
        //                                     height: '30px',
        //                                     "& input": {
        //                                         padding: "24px 20px",
        //                                         color: Colors.textSecondary,
        //                                         // textAlign: "center",
        //                                     },
        //                                     "& fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                         borderWidth: '3px',
        //                                     },
        //                                     "&:hover fieldset, &.Mui-focused fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                     }
        //                                 },
        //                                 "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        //                                     color: Colors.textSecondary,
        //                                 },
        //                                 "& .MuiInputLabel-root.Mui-error": {
        //                                     color: "red",
        //                                 },
        //                             }}
        //                         />
        //                         <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
        //                             Email
        //                         </Typography>
        //                         <TextField
        //                             fullWidth
        //                             // label="Email"
        //                             name="email"
        //                             margin="dense"
        //                             variant="outlined"
        //                             value={formData.email}
        //                             onChange={handleChange}
        //                             error={errors.email}
        //                             helperText={errors.email}
        //                             //  ? "Enter a valid email" : ""}
        //                             InputProps={{
        //                                 style: {
        //                                     borderRadius: '30px',
        //                                     backgroundColor: Colors.white,
        //                                 }
        //                             }}
        //                             sx={{
        //                                 "& .MuiOutlinedInput-root": {
        //                                     height: '30px',
        //                                     "& input": {
        //                                         padding: "24px 20px",
        //                                         color: Colors.textSecondary,
        //                                         // textAlign: "center",
        //                                     },
        //                                     "& fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                         borderWidth: '3px',
        //                                     },
        //                                     "&:hover fieldset, &.Mui-focused fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                     }
        //                                 },
        //                                 "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        //                                     color: Colors.textSecondary,
        //                                 },
        //                                 "& .MuiInputLabel-root.Mui-error": {
        //                                     color: "red",
        //                                 },
        //                             }}
        //                         />

        //                         <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
        //                             Subject
        //                         </Typography>
        //                         <TextField
        //                             // fullWidth
        //                             // label="Subject"
        //                             name="subject"
        //                             margin="dense"
        //                             variant="outlined"
        //                             value={formData.subject}
        //                             onChange={handleChange}
        //                             error={errors.subject}
        //                             helperText={errors.subject}
        //                             // ? "Subject is required" : ""}
        //                             InputProps={{
        //                                 style: {
        //                                     borderRadius: '30px',
        //                                     backgroundColor: Colors.white,
        //                                 }
        //                             }}
        //                             sx={{
        //                                 "& .MuiOutlinedInput-root": {
        //                                     height: '30px',
        //                                     "& input": {
        //                                         padding: "24px 20px",
        //                                         color: Colors.textSecondary,
        //                                         // textAlign: "center",
        //                                     },
        //                                     "& fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                         borderWidth: '3px',
        //                                     },
        //                                     "&:hover fieldset, &.Mui-focused fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                     }
        //                                 },
        //                                 "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        //                                     color: Colors.textSecondary,
        //                                 },
        //                                 "& .MuiInputLabel-root.Mui-error": {
        //                                     color: "red",
        //                                 },
        //                             }}
        //                         />

        //                         <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
        //                             Message
        //                         </Typography>
        //                         <TextField
        //                             fullWidth
        //                             // label="Message"
        //                             name="message"
        //                             margin="dense"
        //                             variant="outlined"
        //                             value={formData.message}
        //                             onChange={handleChange}
        //                             error={errors.message}
        //                             helperText={errors.message}
        //                             //  ? "Message cannot be empty" : ""}
        //                             multiline
        //                             rows={3}
        //                             InputProps={{
        //                                 style: {
        //                                     borderRadius: '30px',
        //                                     backgroundColor: Colors.white,
        //                                     color: Colors.textSecondary,
        //                                 }
        //                             }}
        //                             sx={{
        //                                 "& .MuiOutlinedInput-root": {
        //                                     // height: 35,
        //                                     "& input": {
        //                                         padding: "24px 20px",
        //                                         color: Colors.textSecondary,
        //                                         // textAlign: "center",
        //                                     },
        //                                     "& fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                         borderWidth: '3px',
        //                                     },
        //                                     "&:hover fieldset, &.Mui-focused fieldset": {
        //                                         borderColor: Colors.textSecondary,
        //                                     }
        //                                 },
        //                                 "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        //                                     color: Colors.textSecondary,
        //                                 },
        //                                 "& .MuiInputLabel-root.Mui-error": {
        //                                     color: "red",
        //                                 },
        //                             }}
        //                         />
        //                     </Box>
        //                     <Box mt={2} display="flex" justifyContent="center">

        //                         <Button variant="contained" sx={{ background: Colors.textSecondary, borderRadius: '25px' }} onClick={handleSubmit}>Send</Button>
        //                     </Box>
        //                 </Box>
        //             )}

        //             <Fab
        //                 variant="extended"
        //                 size="medium"
        //                 color="primary"
        //                 sx={{
        //                     position: 'fixed',
        //                     bottom: 36,
        //                     right: 20,
        //                     zIndex: 999, // Lower than the form
        //                     backgroundColor: '#2BB1E6',
        //                     textTransform: 'none',
        //                     display: { xs: 'flex', sm: 'flex' },
        //                     alignItems: 'center',
        //                     justifyContent: 'center'
        //                 }}
        //                 onClick={() => setOpen(!open)}
        //             >
        //                 <Box component="img" src={MessageIcon} alt="Message Icon" sx={{ width: 24, height: 24, marginRight: 1 }} />
        //                 Contact Us
        //             </Fab>
        //         </Box>



        //     </Grid >
        // </Box >

        <Box
            sx={{
                backgroundImage: { xs: "none", md: `url(${bg})` },
                // backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: { xs: '100%', md: screenWidth < 1512 ? "110vh" : "100vh" }, // Adjust as needed
                backgroundSize: "cover",
                // backgroundPosition: "center",
                backgroundRepeat: 'no-repeat',
                bgcolor: Colors.background,
                // display: { xs: 'block', md: "block" },
                // flexDirection:{ xs: 'column', md: 'row' },
                // position: 'relative', p: { xs: 2, sm: 4, md: 6 }
            }}

        // <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ bgcolor: Colors.background, zIndex: 2, position: 'relative', p: { xs: 2, sm: 4, md: 6 } }} overflow="hidden">

        >
            <NavBar />
            <Grid
                container
                spacing={1}
                // alignItems="center"
                // justifyContent="center"
                sx={{ width: '100%', paddingLeft: { xs: '18px', md: '40px' }, marginTop: screenWidth < 1512 ? 0 : '83px' }}
            >
                <Grid item xs={12} md={6} sx={{}}>
                    {/* <Box> */}
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 700,
                            fontFamily: 'MyCustomFont',
                            fontSize: { xs: '24px', lg: (screenWidth < 1512 ? '52px' : '68px') },
                            textAlign: { xs: 'left', md: 'left' },
                            marginBottom: { xs: '20px', md: '40px' }
                        }}
                    >
                        Share the Love of Dogs!
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            color: Colors.black,
                            fontSize: { xs: '16px', md: screenWidth < 1512 ? '25px' : '32px' },
                            textAlign: { xs: 'left', md: 'left' },
                            // marginBottom: '24px'
                            marginBottom: screenWidth < 1512 ? '44px' : '88px'
                        }}
                    >
                        Connecting dog-owners<br /> and non-owners to share<br /> dog care and dog therapy
                    </Typography>

                    <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row', md: 'column', }}
                        sx={{
                            zIndex: 10,
                            position: 'relative'
                        }}
                        // width={{ xs: '50%', md: '35%' }}
                        width={{ xs: '50%', md: screenWidth < 1512 ? '45%' : '350px' }}
                    >
                        {/* <a href="https://apps.apple.com/us/app/social-paws/id6443444135" target="_blank" rel="noopener noreferrer" >
                            <img style={{ width: '100%', height: '20%', marginLeft: 0, marginBottom: { xs: '10px', md: screenWidth < 1512 ? '24px' : '64px' } }} src={apple} alt="App Store" />
                        </a> */}
                        <a href="https://apps.apple.com/us/app/social-paws/id6443444135" target="_blank" rel="noopener noreferrer">
                            <Box
                                component="img"
                                src={apple}
                                alt="App Store"
                                sx={{
                                    width: "100%",
                                    height: "20%",
                                    marginLeft: 0,
                                    marginBottom: { xs: "10px", md: screenWidth < 1512 ? "24px" : "64px" }, // Now it works!
                                }}
                            />
                        </a>

                        {/* <a href="https://play.google.com/store/apps/details?id=com.bytes.socialpaws&pli=1" target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '100%', height: '20%', marginLeft: 0 }} src={google} alt="Google Play" />
                        </a> */}

                        <a href="https://play.google.com/store/apps/details?id=com.bytes.socialpaws&pli=1" target="_blank" rel="noopener noreferrer">
                            <Box
                                component="img"
                                src={google}
                                alt="Google Play"
                                sx={{
                                    width: "100%",
                                    height: "20%",
                                    marginLeft: 0,
                                    // marginBottom: { xs: "10px", md: screenWidth < 1512 ? "24px" : "64px" }, // Now it works!
                                }}
                            />
                        </a>

                    </Box>


                    {/* <Box
                    width={{ xs: '100%', md: '100%' }}
                    sx={{
                        // position: 'relative',
                        // overflow: 'hidden',
                        // display: 'flex',
                        display: { xs: 'none', md: 'flex' },
                        height: { xs: '60vh', md: '86%' },
                        maxHeight: '90vh',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 25,
                            left: 140,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${bg})`,
                            backgroundSize: { xs: 'contain', md: 'contain' },
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            // opacity: 0.3,                  
                            // zIndex: 3,
                        }}
                    /> */}
                    {/* </Box> */}
                    {/* </Box> */}
                </Grid>


                <Box sx={{ position: 'relative', overflow: 'visible', }}>
                    {/* Contact Us Form */}
                    {open && (
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: 80, // Adjust so it's above the button
                                right: 20,
                                width: { xs: '200px', md: '300px' },
                                background: 'white',
                                zIndex: 9999,
                                borderRadius: '28px',
                                p: 2,
                                boxShadow: 3,
                                alignItems: 'center', justifyContent: 'center'
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography sx={{
                                    fontWeight: 600,
                                    fontFamily: 'MyCustomFont1',
                                    fontSize: { xs: '20px', md: '24px' },
                                    textAlign: { xs: 'center', md: 'center' },
                                    color: Colors.textSecondary,
                                    // marginBottom: '16px'
                                }}>Contact Us!</Typography>
                                <IconButton sx={{
                                    color: Colors.textSecondary,
                                    // border: '2px solid #2BB1E6', 
                                    // borderRadius: '50px', 
                                    // padding: '4px' 
                                }} onClick={() => setOpen(false)}>
                                    <CloseIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Box>

                            <Box sx={{ width: '90%', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
                                    Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="name"
                                    // label="Name"
                                    margin="dense"
                                    variant="outlined"
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                    helperText={errors.name
                                        // ? "Name is required" : ""
                                    }
                                    InputProps={{
                                        style: {
                                            borderRadius: '30px',
                                            backgroundColor: Colors.white,
                                        }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            height: '30px',
                                            "& input": {
                                                padding: "24px 20px",
                                                color: Colors.textSecondary,
                                                // textAlign: "center",
                                            },
                                            "& fieldset": {
                                                borderColor: Colors.textSecondary,
                                                borderWidth: '3px',
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                                borderColor: Colors.textSecondary,
                                            }
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                            color: Colors.textSecondary,
                                        },
                                        "& .MuiInputLabel-root.Mui-error": {
                                            color: "red",
                                        },
                                    }}
                                />
                                <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
                                    Email
                                </Typography>
                                <TextField
                                    fullWidth
                                    // label="Email"
                                    name="email"
                                    margin="dense"
                                    variant="outlined"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                    helperText={errors.email}
                                    //  ? "Enter a valid email" : ""}
                                    InputProps={{
                                        style: {
                                            borderRadius: '30px',
                                            backgroundColor: Colors.white,
                                        }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            height: '30px',
                                            "& input": {
                                                padding: "24px 20px",
                                                color: Colors.textSecondary,
                                                // textAlign: "center",
                                            },
                                            "& fieldset": {
                                                borderColor: Colors.textSecondary,
                                                borderWidth: '3px',
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                                borderColor: Colors.textSecondary,
                                            }
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                            color: Colors.textSecondary,
                                        },
                                        "& .MuiInputLabel-root.Mui-error": {
                                            color: "red",
                                        },
                                    }}
                                />

                                <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, mb: 0, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
                                    Subject
                                </Typography>
                                <TextField
                                    // fullWidth
                                    // label="Subject"
                                    name="subject"
                                    margin="dense"
                                    variant="outlined"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    error={errors.subject}
                                    helperText={errors.subject}
                                    // ? "Subject is required" : ""}
                                    InputProps={{
                                        style: {
                                            borderRadius: '30px',
                                            backgroundColor: Colors.white,
                                        }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            height: '30px',
                                            "& input": {
                                                padding: "24px 20px",
                                                color: Colors.textSecondary,
                                                // textAlign: "center",
                                            },
                                            "& fieldset": {
                                                borderColor: Colors.textSecondary,
                                                borderWidth: '3px',
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                                borderColor: Colors.textSecondary,
                                            }
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                            color: Colors.textSecondary,
                                        },
                                        "& .MuiInputLabel-root.Mui-error": {
                                            color: "red",
                                        },
                                    }}
                                />

                                <Typography variant="subtitle1" sx={{ color: Colors.textSecondary, fontFamily: 'MyCustomFont1', fontWeight: 600, }}>
                                    Message
                                </Typography>
                                <TextField
                                    fullWidth
                                    // label="Message"
                                    name="message"
                                    margin="dense"
                                    variant="outlined"
                                    value={formData.message}
                                    onChange={handleChange}
                                    error={errors.message}
                                    helperText={errors.message}
                                    //  ? "Message cannot be empty" : ""}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: {
                                            borderRadius: '30px',
                                            backgroundColor: Colors.white,
                                            color: Colors.textSecondary,
                                        }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            // height: 35,
                                            "& input": {
                                                padding: "24px 20px",
                                                color: Colors.textSecondary,
                                                // textAlign: "center",
                                            },
                                            "& fieldset": {
                                                borderColor: Colors.textSecondary,
                                                borderWidth: '3px',
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                                borderColor: Colors.textSecondary,
                                            }
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                            color: Colors.textSecondary,
                                        },
                                        "& .MuiInputLabel-root.Mui-error": {
                                            color: "red",
                                        },
                                    }}
                                />
                            </Box>
                            <Box mt={2} display="flex" justifyContent="center">

                                <Button variant="contained" sx={{ background: Colors.textSecondary, borderRadius: '25px' }} onClick={handleSubmit}>Send</Button>
                            </Box>
                        </Box>
                    )}

                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 36,
                            right: { xs: 20, md: 40 },
                            // margingRight: '140px',
                            zIndex: 999, // Lower than the form
                            backgroundColor: '#2BB1E6',
                            textTransform: 'none',
                            display: { xs: 'flex', sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={() => setOpen(!open)}
                    >
                        <Box component="img" src={MessageIcon} alt="Message Icon" sx={{ width: 24, height: 24, marginRight: 1 }} />
                        Contact Us
                    </Fab>
                </Box>
            </Grid>
        </Box>
    );
};

export default LoveOfDogs;

