import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Grid, Link, Container } from '@mui/material';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import img1 from "../../asstes/images/gallery/gallery/img1.png"
import img2 from "../../asstes/images/gallery/gallery/img2.png"
import img3 from "../../asstes/images/gallery/gallery/img3.png"
import img4 from "../../asstes/images/gallery/gallery/img4.png"
import img5 from "../../asstes/images/gallery/gallery/img5.png"
import img6 from "../../asstes/images/gallery/gallery/img6.png"
import img7 from "../../asstes/images/gallery/gallery/img7.png"
import img8 from "../../asstes/images/gallery/gallery/img8.png"
import img9 from "../../asstes/images/gallery/gallery/img9.png"
import Colors from '../../asstes/color';
import { useLocation } from 'react-router-dom';

const GalleryDetailScreen = () => {
    const location = useLocation();
    const { data } = location.state || {};

    const images = data?.Image ? data.Image.slice(1) : [];

    return (
        <div>
            <NavBar screen={"event"} />
            <Container sx={{ textAlign: 'center', my: 4 }}>
                <Typography variant="h4" sx={{
                    fontFamily: 'MyCustomFont1',
                    // left: '50%',
                    fontWeight: 600,
                    color: Colors.textSecondary,
                    fontSize: { xs: '1.8rem', md: '2.5rem' },
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    // zIndex: 1,
                }}>
                    {data?.name}
                </Typography>
            </Container>

            <Container maxWidth={false} sx={{ px: { xs: 2, sm: 4, md: 6 }, mb: 2, textAlign: 'center', }}>
                <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
                    {/* {[
                        img1,
                        img2,
                        img3,
                        img4,
                        img5,
                        img6,
                        img7,
                        img8,
                        img9,
                    ].map((image, index) => ( */}
                    {images?.map((image, index) => (
                        <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            key={index}
                            sx={{
                                marginTop: { xs: index % 3 === 1 ? 10 : 0, sm: index % 3 === 1 ? 15 : 0, md: index % 3 === 1 ? 20 : 0 },
                            }}
                        >
                            <Box
                                component="img"
                                src={image}
                                alt={`Gallery Image ${index + 1}`}
                                sx={{
                                    width: '100%',
                                    borderRadius: 3,
                                    objectFit: 'cover',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Footer />
        </div>
    );
};

export default GalleryDetailScreen;
