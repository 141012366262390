import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const GalleryReducer = (state, action) => {
    switch (action.type) {
        case "get_all_gallery":
            return { ...state, GalleryList: action.payload };
        case "get_all_gallery_status":
            return { ...state, GalleryListStatus: action.payload };
        case "clear_get_all_gallery_status":
            return { ...state, GalleryListStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllGallery = (dispatch) => async () => {
    try {
        const response = await listAPI.get('gallery/getAllGallery');
        dispatch({ type: "get_all_gallery", payload: response.data });
        dispatch({ type: "get_all_gallery_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_gallery_status", payload: 400 });
    }
};

const clearGetAllGalleryStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_gallery_status" });
};




export const { Provider, Context } = CreateDataContext(
    GalleryReducer,
    {
        getAllGallery, clearGetAllGalleryStatus,
    },
    {
        GalleryList: [],
        GalleryListStatus: "",
    }
);
