import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const ContactReducer = (state, action) => {
    switch (action.type) {
        case "create_contact":
            return { ...state, CreateContact: action.payload };
        case "create_contact_status":
            return { ...state, CreateContactStatus: action.payload };
        case "clear_create_contact_status":
            return { ...state, CreateContactStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};


/** Create contact **/
const createMessage = dispatch => async ({ name, email, subject, message }) => {
    try {
        let data = {
            name: name,
            email: email,
            subject: subject,
            message: message,
        }

        const response = await listAPI.post("contact/add", data, config);
        dispatch({ type: "create_contact", payload: response.data });
        dispatch({ type: "create_contact_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_contact_status", payload: 400 })
    }
}

const clearCreateContactStatus = dispatch => () => {
    dispatch({ type: "clear_create_contact_status" });
};


export const { Provider, Context } = CreateDataContext(
    ContactReducer,
    {
        createMessage, clearCreateContactStatus,
    },
    {
        CreateContact: [],
        CreateContactStatus: "",
    }
);
