import { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, Container, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import bg_wp1 from '../../asstes/images/bg_wp1.png';
import Colors from '../../asstes/color';
import image1 from '../../asstes/images/img1.png';
import image2 from '../../asstes/images/girl_dog.png';
import image3 from '../../asstes/images/people_dog.png';



const useStyles = makeStyles((theme) => ({
    background: {
        width: '100%',
        height: '55vh',//100
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // overflow: 'hidden',
        backgroundColor: Colors.textSecondary,
        marginTop: -100,
        // [theme.breakpoints.up('lg')]: {
        //     // Styles for screens 1200px and above
        //     marginTop: -100,
        // },
        [theme.breakpoints.down('xs')]: {
            marginTop: -180,
        },
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: -180,
        // }
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '90%', // Use a fixed width or a percentage to control spacing
        maxWidth: '1200px', // Optional: max width for larger screens
        opacity: 1,
        transition: 'opacity 1s ease-in-out',
        // mt:-100
    },
    scrollingText: {
        fontSize: '2rem',
        color: '#fff',
        whiteSpace: 'nowrap',
        marginRight: '20px', // Space between text and image
    },
    hiddenText: {
        opacity: 0, // Hide text between transitions
    },
    image: {
        width: '270px',
        height: 'auto',
        objectFit: 'cover',
    },
    responsiveImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: '8px',
        marginLeft: 'auto',
        [theme.breakpoints.up('xs')]: {
            width: '60px',
            height: '60px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '80px',
            height: '80px',
        },
        [theme.breakpoints.up('md')]: {
            // width: '100px',
            // height: '100px',
            width: '500px',
            height: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '400px',
            height: '500px',
            // marginTop: -10
        },
    },
}));


const ScrollingText = () => {
    const buttonRef = useRef(null);
    const classes = useStyles();
    const [selectedTeam, setSelectedTeam] = useState(null);

    const messages = {
        default: {
            text: (
                <>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>Social Paws prompts you to join</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>Team Paws or Team Social.</Typography>
                </>
            ),
            image: image1,
        },
        teamSocial: {
            text: (
                <>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>You’re on Team Social if you love dogs,</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>but can’t own one and would love to</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>spend time with a neighbor’s dog!</Typography>
                </>
            ),
            image: image2,
        },
        teamPaws: {
            text: (
                <>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>You’re on Team Paws if you are a</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>dog-owner who wants extra</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>support with dog-care and are open</Typography>
                    <Typography display="block" sx={{ fontFamily: 'MyCustomFont1', fontWeight: 500, fontSize: { xs: '12px', md: '20px' } }}>to sharing your dog’s love with a neighbor!</Typography>
                </>
            ),
            image: image3,
        },
    };

    const [messageIndex, setMessageIndex] = useState(0);
    const [showText, setShowText] = useState(true);

    useEffect(() => {
        const showDuration = 7000;
        const hideDuration = 1000;

        const interval = setInterval(() => {
            setShowText(false);
            setTimeout(() => {
                setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                setShowText(true);
            }, hideDuration);
        }, showDuration + hideDuration);

        return () => clearInterval(interval);
    }, [messages.length]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setSelectedTeam(''); // Reset selected team if click is outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const currentMessage = selectedTeam ? messages[selectedTeam] : messages.default;

    return (
        <Box
            sx={{
                backgroundImage: `url(${bg_wp1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'cover',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                // alignItems: 'center',
                minHeight: { xs: '35vh', md: '80vh' },
                height: { xs: '20vh', md: '60vh' },
            }}
        >
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', mt: { xs: 2, md: 28 }, ml: { xs: 2, md: 6 }, }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: { xs: '24px', md: '35px' },
                        color: Colors.white,
                        mb: 2,
                        mr: 2,
                    }}
                >
                    There are two Teams!
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        // zIndex: 10,
                        position: 'relative',
                        mr: 2,
                        ml: 0,
                    }}
                    ref={buttonRef}
                >
                    <Box
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: { xs: '8px', md: '20px' },
                            color: Colors.textSecondary,
                            padding: '8px 16px',
                            borderColor: Colors.secondary,
                            borderRadius: { xs: '10px', md: '28px' },
                            bgcolor: Colors.white,
                            cursor: 'pointer',
                            // zIndex: 11,
                        }}
                        onClick={() => setSelectedTeam('teamSocial')}
                    >
                        Team Social
                    </Box>
                    <Box
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: { xs: '8px', md: '20px' },
                            color: Colors.textSecondary,
                            padding: '8px 16px',
                            borderColor: Colors.secondary,
                            borderRadius: { xs: '10px', md: '28px' },
                            bgcolor: Colors.white,
                            cursor: 'pointer',
                            // zIndex: 11,
                        }}
                        onClick={() => setSelectedTeam('teamPaws')}
                    >
                        Team Paws
                    </Box>
                </Box>

             
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '90%',
                        maxWidth: '1200px',
                        mt: { xs: 3, md: -14 },
                    }}
                >
                    <Box sx={{ fontSize: { xs: '3rem', md: '1.5rem' }, color: '#fff', whiteSpace: 'nowrap', mr: 3 }}>
                        {currentMessage.text}
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            mt: -8,
                            ml: { xs: 3, md: 18 },
                            borderRadius: '8px',
                            width: { xs: '125px', sm: '280px', md: '500px', lg: '400px' },
                            height: { xs: '160px', sm: '280px', md: '300px', lg: '500px' },
                        }}
                    >
                        <Box
                            component="img"
                            src={currentMessage.image}
                            alt="Message related"
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', mt: { xs: '30px', md: '100px' }, justifyContent: 'space-between' }}>
                <Box sx={{ ml: { xs: 2, md: 6 }, }}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: { xs: '15px', md: '35px' },
                            color: Colors.white,
                            mb: 2,
                            mr: 2,
                        }}
                    >
                        There are two Teams!
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            // zIndex: 10,
                            position: 'relative',
                            mr: 2,
                            ml: 0, mt: { xs: '20px', md: '40px' }
                        }}
                        ref={buttonRef}
                    >
                        <Box
                            sx={{
                                fontFamily: 'MyCustomFont1',
                                fontWeight: 600,
                                fontSize: { xs: '8px', md: '20px' },
                                color: Colors.textSecondary,
                                padding: '8px 16px',
                                borderColor: Colors.secondary,
                                borderRadius: { xs: '10px', md: '28px' },
                                bgcolor: Colors.white,
                                cursor: 'pointer',
                                // zIndex: 11,
                            }}
                            onClick={() => setSelectedTeam('teamSocial')}
                        >
                            Team Social
                        </Box>
                        <Box
                            sx={{
                                fontFamily: 'MyCustomFont1',
                                fontWeight: 600,
                                fontSize: { xs: '8px', md: '20px' },
                                color: Colors.textSecondary,
                                padding: '8px 16px',
                                borderColor: Colors.secondary,
                                borderRadius: { xs: '10px', md: '28px' },
                                bgcolor: Colors.white,
                                cursor: 'pointer',
                                // zIndex: 11,
                            }}
                            onClick={() => setSelectedTeam('teamPaws')}
                        >
                            Team Paws
                        </Box>
                    </Box>

                    <Box sx={{ mt: { xs: '20px', md: '40px' } }}>
                        <Box sx={{ fontSize: { xs: '3rem', md: '1.5rem' }, color: '#fff', whiteSpace: 'nowrap', mr: 3 }}>
                            {currentMessage.text}
                        </Box>
                    </Box>

                    <Box sx={{ mt: { xs: '10px', md: '60px' } }}>
                        {/* <Box sx={{ fontSize: { xs: '3rem', md: '1.5rem' }, color: '#fff', whiteSpace: 'nowrap', mr: 3 }}>
                            {currentMessage.text}
                        </Box> */}
                        <Typography sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 500,
                            fontSize: { xs: '11px', md: '20px' },
                            color: '#FFFFFFA6',
                            mb: 2,
                            mr: 2,
                        }}>Learn more</Typography>
                    </Box>
                </Box>

                <Box sx={{ mt: '50px', width: '45%', mr: { xs: 2, md: 6 }, display: { xs: 'none', md: 'flex' } }}>
                    <Box
                        component="img"
                        src={currentMessage.image}
                        alt="Message related"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default ScrollingText;
