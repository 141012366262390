import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import { Box, Fab, Typography, Container, CssBaseline, Grid2 } from "@mui/material";
import LoveOfDogs from "./LoveOfDogs";
import WhatsSP from "./WhatsSP"
import ScrollingText from "./ScrollingText";
import TwoTeamsText from "./TwoTeamsText";
import TestimonialCarousel from "./TestimonialCarousel";
import FollowInstaText from "./FollowInstaText";
import InstagramPost from "./InstagramPost";
import EventView from "./EventView";
import SponsersView from "./SponsersView";
import Footer from "../../components/Footer";

export default function HomeScreen() {

    return (
        <React.Fragment>
            {/* <NavBar /> */}
            <LoveOfDogs />
            <WhatsSP />
            <ScrollingText />
            <TestimonialCarousel />
            <FollowInstaText />
            <InstagramPost />
            <EventView />
            <SponsersView />
            <Footer />
        </React.Fragment>
    );
}


