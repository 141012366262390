import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Colors from "../../asstes/color";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import qrImg from '../../asstes/images/qr.svg'
import { Context as EventContext } from "../../context/EventContext";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

const Calendar = () => {
    const location = useLocation();
    const { data, isHome } = location.state || {};
    const [events, setEvents] = useState([]);
    const [isHomeValue, setIsHomeValue] = useState(false)
    const [currentDate, setCurrentDate] = useState(new Date());
    const [days, setDays] = useState([]);
    const [formValues, setFormValues] = useState({
        Name: '',
        Phone: '',
        Email: '',
    });
    const [selectedEvent, setSelectedEvent] = useState([]);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const { state: { EventList, EventListStatus, CreateBookingStatus }, getAllEvents, clearGetAllEventsStatus, createBooking, clearCreateBookingStatus, } = useContext(EventContext);

    useEffect(() => {
        getAllEvents()
    }, []);


    useEffect(() => {
        if (!data) return;

        const eventDate = new Date(data.event_date);

        // Filter based on current year and month
        const isCurrentMonthEvent =
            eventDate.getFullYear() === currentDate.getFullYear() &&
            eventDate.getMonth() === currentDate.getMonth();

        if (isCurrentMonthEvent) {
            const formattedEvent = [{
                id: data._id,
                date: new Date(data.event_date).getUTCDate(),
                name: data.event_name,
                description: data.event_description,
                image: data.image,
                date_time: data.event_date,
                time: data.event_time
            }];

            setSelectedEvent(formattedEvent);
        } else {
            setSelectedEvent([]); // No events for the current month
        }

        setIsHomeValue(isHome)
    }, [data, currentDate, isHome]);




    useEffect(() => {
        const filteredEvents = EventList?.data?.filter((event) => {
            const eventDate = new Date(event.event_date);
            // const eventDate =  new Date(event.event_date).getUTCDate()

            // const eventDateUTC = new Date(eventDate.toUTCString());

            // console.log("event date", event.event_date)

            // console.log(" date", eventDateUTC)
            return (
                eventDate.getFullYear() === currentDate.getFullYear() &&
                eventDate.getMonth() === currentDate.getMonth()
            );
        }).map((event) => ({
            id: event?._id,
            // date: new Date(event.event_date).getDate(),
            date: new Date(event.event_date).getUTCDate(),
            name: event.event_name,
            description: event.event_description,
            image: event.image,
            date_time: event.event_date,
            time: event.event_time
        }));
        setEvents(filteredEvents);
    }, [EventList, EventListStatus, currentDate]);


    useEffect(() => {
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const daysInMonth = Array.from({ length: lastDayOfMonth.getDate() }, (_, i) => i + 1);
        setDays(daysInMonth);
    }, [currentDate]);

    useEffect(() => {
        if (CreateBookingStatus === 201) {
            console.log("success")
            setOpen2(true)
            setOpen1(false)
            setOpen(false)
            setOpen3(false)
            setFormValues({
                Name: '',
                Phone: '',
                Email: '',
            })
            setSelectedEvent([])
            clearCreateBookingStatus();
        } else if (CreateBookingStatus === 400 || CreateBookingStatus === 404) {
            clearCreateBookingStatus();
        }
    }, [CreateBookingStatus]);


    const handleSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        try {
            if (selectedEvent.length === 0) {
                setOpen1(true);
                setOpen(false);
                setOpen2(false)
                setOpen3(false)
            } else if (formValues.Name === '') {
                setOpen(true);
                setOpen1(false);
                setOpen2(false)
                setOpen3(false)
            } else if (formValues.Email === '') {
                setOpen(true);
                setOpen1(false);
                setOpen2(false)
                setOpen3(false)
            } else if (!emailRegex.test(formValues.Email)) {
                setOpen3(true)
                setOpen(false);
                setOpen1(false);
                setOpen2(false)
            } else if (formValues.Phone === '') {
                setOpen(true);
                setOpen1(false);
                setOpen2(false)
                setOpen3(false)
            } else {
                // setOpen(false);
                // setOpen1(false);
                // setOpen2(false)
                // setOpen3(false)
                createBooking({
                    name: formValues.Name,
                    email: formValues.Email,
                    phone: formValues.Phone,
                    event_id: selectedEvent?.id,
                });
            }
        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleInputChange = (label, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [label]: value,
        }));
    };

    const handleGridClick = (day, event) => {
        if (event) {
            setIsHomeValue(false)
            setSelectedEvent(event)
            // setFormattedDate(formatDate(event.event_date));
        } else {
            setSelectedEvent([])
        }
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen1(false);
    };

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen2(false);
    };

    // const formatDate = (dateString) => {
    //     return format(new Date(dateString), "MMM do"); // Output: "Nov 21st"
    // };
    const formatDate = (dateString) => {
        const utcDate = toZonedTime(new Date(dateString), "UTC");
        return format(utcDate, "MMM do"); // Output: "Feb 16th"
    };

    // Function to handle moving to the next month
    const handleNextMonth = () => {
        setCurrentDate((prevDate) => {
            const nextMonth = new Date(prevDate);
            nextMonth.setMonth(prevDate.getMonth() + 1, 1);
            return nextMonth;
        });
    };

    // Function to handle moving to the previous month
    const handlePreviousMonth = () => {
        setCurrentDate((prevDate) => {
            const prevMonth = new Date(prevDate);
            prevMonth.setMonth(prevDate.getMonth() - 1, 1); // Move back one month
            return prevMonth;
        });
    };

    const renderDays = () => {
        // const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        // const daysInMonth = new Date(
        //     currentDate.getFullYear(),
        //     currentDate.getMonth() + 1,
        //     0
        // ).getDate();


        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay(); // Get the starting weekday (0=Sunday, 1=Monday, etc.)
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate(); // Total days in month

        const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        const additionalBoxes = (7 - ((daysInMonth + firstDayOfMonth) % 7)) % 7;
        // const daysInWeek = 7;
        // const totalDays = days.length;
        // const additionalBoxes = (daysInWeek - (totalDays % daysInWeek)) % daysInWeek;
        return (
            <>
                {/* <Grid  spacing={1} justifyContent="space-between"> */}
                {daysOfWeek.map((day, index) => (
                    <Grid item
                        xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1}
                        key={index}
                        sx={{
                            height: { xs: "30px", md: "40px" },
                            border: "1px solid #2BB1E6",
                            borderRadius: '10px', display: 'flex', // Ensures flex container
                            alignItems: 'center', // Vertically centers content
                            justifyContent: 'center'
                        }}>
                        <Typography
                            variant="body1"
                            align="center"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 12, sm: 16 },
                                color: Colors.black,
                                fontFamily: 'MyCustomFont1',
                            }}
                        >
                            {day}
                        </Typography>
                    </Grid>
                ))}
                {/* </Grid> */}

                {[...Array(firstDayOfMonth)].map((_, index) => (
                    <Grid
                        item
                        xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1}
                        key={`empty-start-${index}`}
                        sx={{
                            height: { xs: "60px", md: "160px" },
                            border: "1px solid #2BB1E6",
                            borderRadius: '10px',
                        }}
                    />
                ))}


                {days?.map((day) => {
                    const event = events?.find((e) => e.date === day);
                    return (
                        <Grid
                            item
                            xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1} //lg1.3 md 1.5 sm 2 xs 4
                            key={day}
                            onClick={() => handleGridClick(day, event)}
                            sx={{ height: { xs: "60px", md: "160px" } }}
                            style={{ cursor: 'pointer', border: "1px solid #2BB1E6", borderRadius: '10px', backgroundColor: event ? "rgba(43, 177, 230, 0.2)" : "transparent" }}
                        >
                            <Typography variant="body1" sx={{ fontSize: { xs: 10, sm: 15 }, fontFamily: 'MyCustomFont1', }}>
                                {day}
                            </Typography>
                            {event && (
                                <Typography variant="body2" align="center" color="primary" sx={{
                                    fontFamily: 'MyCustomFont1',
                                    fontWeight: 400,
                                    fontSize: { xs: 10, sm: 15 },
                                    color: Colors.black,
                                    mt: { xs: 0, md: 2 },
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                    wordWrap: "break-word",

                                }}>
                                    {event.name}
                                </Typography>
                            )}
                        </Grid>
                    );
                })}

                {[...Array(additionalBoxes)].map((_, index) => (
                    <Grid
                        item
                        xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1}
                        key={`empty-${index}`}
                        sx={{
                            height: { xs: "60px", md: "160px" },
                            border: "1px solid #2BB1E6",
                            borderRadius: '10px',
                        }}
                    />
                ))}
            </>
        )
    };

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Typography variant="h4" align="center" color="primary" gutterBottom sx={{
                fontFamily: 'MyCustomFont',
                fontWeight: 700,
                fontSize: { xs: 24, sm: 30, md: 35 },
                color: Colors.textSecondary,
                textDecoration: 'none',
                mt: 2
            }}>
                Events
            </Typography>
            <Grid container spacing={1} sx={{ maxWidth: "95%", margin: "0 auto", mb: 3 }}>
                <Grid
                    item
                    xs={12}
                    sm={18}
                    md={13.5}
                    lg={11.7}
                    xl={12}
                    sx={{
                        // height: "80px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        flexDirection: 'row',
                        justifyContent: "space-between",
                        backgroundColor: Colors.green,
                        borderRadius: "10px",
                        border: "1px solid #2BB1E6",
                        padding: { xs: 1, sm: 1 },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "MyCustomFont1",
                            fontWeight: 600,
                            fontSize: { xs: 16, sm: 20, md: 22 },
                            color: Colors.white,
                        }}
                    >
                        {currentDate.toLocaleString("default", { month: "long" })}
                    </Typography>
                    <Box>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: Colors.white,
                                fontSize: { xs: 20, sm: 24, md: 28 },
                                cursor: "pointer", // Optional: Make it clickable  
                            }}
                            onClick={handlePreviousMonth}
                        />
                        <ArrowForwardIosIcon
                            sx={{
                                color: Colors.white,
                                fontSize: { xs: 20, sm: 24, md: 28 },
                                cursor: "pointer", // Optional: Make it clickable  
                            }}
                            onClick={handleNextMonth}
                        />
                    </Box>
                </Grid>
                {renderDays()}
            </Grid>
            <Box sx={{ backgroundColor: Colors.textSecondary, width: '100%', }}>
                <Box sx={{ padding: { xs: 2, sm: 2, md: 5 }, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', display: 'flex' }}>
                    <Box sx={{ width: { xs: '100%', md: selectedEvent?.length !== 0 ? '90%' : '40%', }, paddingRight: { xs: 0, md: 15 } }}>
                        <Typography variant="h5" sx={{
                            fontFamily: "MyCustomFont1",
                            fontWeight: 600,
                            fontSize: { xs: 24, sm: 30, md: 35 },
                            color: Colors.white,
                        }}>Sign Up</Typography>

                        {selectedEvent?.length !== 0 &&
                            <Typography variant="subtitle1" sx={{ color: Colors.white, mb: 2, mt: 3, fontFamily: 'MyCustomFont1', fontSize: { xs: 15, sm: 15, md: 20 }, }}>
                                {/* {isHome ? ((selectedEvent?.name) (formatDate(selectedEvent[0]?.date_time))) : selectedEvent?.name} */}
                                {isHomeValue
                                    ? (selectedEvent
                                        ? `${selectedEvent[0]?.name} ${formatDate(selectedEvent[0]?.date_time)} ${selectedEvent[0]?.time}`
                                        : null)
                                    : `${selectedEvent?.name} ${formatDate(selectedEvent.date_time)}  ${selectedEvent?.time}`}
                                {/* // {selectedEvent?.name}  {formatDate(selectedEvent.date_time)}  {selectedEvent?.time} */}
                            </Typography>}

                        {["Name", "Phone", "Email"].map((label, index) => (
                            <Box key={index} sx={{ mb: 1, mt: 3 }}>
                                <Typography variant="subtitle1" sx={{ color: Colors.white, mb: 0 }}>
                                    {label}*
                                </Typography>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    value={formValues[label]}
                                    onChange={(e) => handleInputChange(label, e.target.value)}
                                    InputProps={{
                                        style: {
                                            borderRadius: '30px',
                                            backgroundColor: Colors.textSecondary,
                                        }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            height: 35,
                                            "& input": {
                                                padding: "24px 20px",
                                                color: Colors.white,
                                                // textAlign: "center",
                                            },
                                            "& fieldset": {
                                                borderColor: Colors.white,
                                                borderWidth: '3px',
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                                borderColor: Colors.white,
                                            }
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                            color: Colors.white,
                                        },
                                        "& .MuiInputLabel-root.Mui-error": {
                                            color: "red",
                                        },
                                    }}
                                />

                            </Box>
                        ))}

                        <Box sx={{
                            mt: 3,
                            display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between'
                            // paddingLeft: { xs: 2, sm: 8, md: 5 },
                        }}>
                            <Button variant="contained" sx={{ mt: 0, mb: 1, bgcolor: 'white', color: 'black', }} onClick={() => handleSubmit()}>
                                Join
                            </Button>
                            {open && !open1 && !open2 && !open3 && (
                                <Button variant="contained" sx={{ mt: 0, mb: 1, bgcolor: 'red', color: 'white', borderRadius: '8px', textTransform: 'none', pointerEvents: 'none' }}>
                                    Please fill required field!
                                </Button>
                            )}

                            {!open && open1 && !open2 && !open3 && (
                                <Button variant="contained" sx={{ mt: 0, mb: 1, bgcolor: 'red', color: 'white', borderRadius: '8px', textTransform: 'none', pointerEvents: 'none' }}>
                                    Please select an event!
                                </Button>
                            )}

                            {!open && !open1 && !open2 && open3 && (
                                <Button variant="contained" sx={{ mt: 0, mb: 1, bgcolor: 'red', color: 'white', borderRadius: '8px', textTransform: 'none', pointerEvents: 'none' }}>
                                    Invalid Email!
                                </Button>
                            )}

                            {!open && !open1 && open2 && !open3 && (
                                <Button variant="contained" sx={{ mt: 0, mb: 1, bgcolor: 'green', color: 'white', borderRadius: '8px', textTransform: 'none', pointerEvents: 'none' }}>
                                    Event signup success!
                                </Button>
                            )}

                        </Box>
                    </Box>
                    {selectedEvent?.length !== 0 &&
                        <Box >
                            <Box sx={{ justifyContent: { xs: 'center', md: 'space-between' }, display: 'flex', flexDirection: 'row', }}>
                                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: '14px', }}>
                                    {/* {selectedEvent.length !== 0 && */}

                                    <Typography variant="subtitle1" sx={{
                                        color: Colors.white, mb: 1,
                                        fontFamily: 'MyCustomFont',
                                        fontWeight: 600,
                                        //  textAlign: 'center'
                                    }}>
                                        Ticket Price!
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{
                                        color: Colors.white, mb: 1,
                                        fontFamily: 'MyCustomFont',
                                        fontWeight: 600,
                                        fontSize: { xs: 15, sm: 15, md: 24 },
                                        //  textAlign: 'center'
                                    }}>
                                        $20
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{
                                        color: Colors.white, mb: 1,
                                        fontFamily: 'MyCustomFont',
                                        fontWeight: 600,
                                        //  textAlign: 'center'
                                    }}>
                                        Pay with Venmo
                                    </Typography>

                                    <img
                                        src={qrImg}
                                        alt="Signup illustration"
                                        style={{
                                            width: '100px',
                                            height: '112px',
                                            objectFit: 'contain',
                                            // width: '150px',  // Increase width
                                            // height: '200px', // Increase height
                                            // objectFit: 'contain',
                                            // borderRadius: '10px',
                                        }}
                                    />
                                    <Typography variant="subtitle1" sx={{
                                        color: Colors.white, mt: 1,
                                        fontFamily: 'MyCustomFont', fontWeight: 600,
                                        //  textAlign: 'center'
                                    }}>
                                        or
                                    </Typography>

                                    <a
                                        href="https://account.venmo.com/u/socialpawsapp"
                                        target="_blank"
                                        rel="noopener noreferrer" underline="none">
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: Colors.white, mt: 1,
                                                // textAlign: 'center',
                                                fontFamily: 'MyCustomFont1', cursor: 'pointer', textDecoration: 'underline'
                                            }}
                                        >
                                            Click link here!
                                        </Typography>
                                    </a>
                                </Box>


                                <Box sx={{ display: { xs: 'none', md: 'block' }, }}>
                                    {isHomeValue ?
                                        <img
                                            src={selectedEvent[0]?.image}
                                            alt="Signup illustration"
                                            style={{
                                                width: '400px',
                                                height: '350px',
                                                objectFit: 'cover',
                                                borderRadius: '10px',
                                            }}
                                        /> :
                                        <img
                                            src={selectedEvent?.image}
                                            alt="Signup illustration"
                                            style={{
                                                width: '400px',
                                                height: '350px',
                                                objectFit: 'cover',
                                                borderRadius: '10px',
                                            }}
                                        />}

                                    <Box
                                        sx={{
                                            // width: '100%',
                                            // width: '300px',
                                            // height: { xs: '150px', sm: '200px', md: '250px' },
                                            width: '400px',
                                            display: { xs: '', md: 'block' },
                                            // alignItems: 'center',
                                            // justifyContent: 'center'
                                        }}
                                    >
                                        {/* <Typography variant="subtitle1" sx={{ fontFamily: 'MyCustomFont1', color: Colors.white, mt: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    {selectedEvent?.description}
                                </Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', fontFamily: 'MyCustomFont1', color: Colors.white, mt: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    {isHomeValue ? selectedEvent[0]?.description : selectedEvent?.description}
                                </Typography>
                            </Box>
                        </Box>}

                </Box>
            </Box>

            {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Please fill required field!
                </Alert>
            </Snackbar>

            <Snackbar open={open1} autoHideDuration={3000} onClose={handleClose1}>
                <Alert
                    onClose={handleClose1}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Please select an event!
                </Alert>
            </Snackbar>

            <Snackbar open={open2} autoHideDuration={3000} onClose={handleClose2}>
                <Alert
                    onClose={handleClose2}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Event signup success!
                </Alert>
            </Snackbar> */}

            {/* footer */}
            <Footer />
        </Box >
    );
};

export default Calendar;
